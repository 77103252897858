import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
  } from '@ionic/vue';
  import { ref } from 'vue';
  import {
    heartSharp,
    trashSharp,
  } from 'ionicons/icons';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'MainMenu',
  setup(__props) {

  
return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(IonMenu), {
    "content-id": "main-content",
    type: "overlay"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonList), { id: "inbox-list" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonMenuToggle), { "auto-hide": "false" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/info",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'coffee'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.about")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/open",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'book-atlas'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.data")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/stories",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'book-open'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.stories")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/instructions",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'receipt'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.tutorial")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/work",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'wand-magic-sparkles'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.work.tab")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/advanced",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'rocket'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.advanced")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    button: "",
                    "router-link": "/login",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        "aria-hidden": "true",
                        icon: ['fas', 'user-secret'],
                        slot: "start",
                        "fixed-width": ""
                      }),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("titles.login")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})