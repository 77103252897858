import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "swipeWrap",
  ref: "swipe"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "version ion-hide-sm-down" }
const _hoisted_4 = { class: "smallheader" }

import {
    IonMenu,
    IonMenuToggle,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import {
  IonLabel,
  IonList,
  IonItem,
  IonToggle,
  IonImage,
  IonThumbnail,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { IonButton, IonContent, IonPopover } from "@ionic/vue";
import { ref, computed, onMounted, watch, onBeforeMount } from "vue";
import { popoverController } from "@ionic/vue";

// test swiping. works. move to app ...
/*
import { createGesture } from '@ionic/vue';

const swipe = ref()
const gesture = ref() 
onMounted(() => {
  console.log("swipe target:",swipe.value, swipe.value.name,swipe.value.id)
    gesture.value = createGesture({
    el: swipe.value,

    onMove: (detail) => { onMove(detail); }
  })

  gesture.value.enable();

})

const onMove = (detail) => {
  //const type = detail.type;
  //const currentX = detail.currentX;
  //const deltaX = detail.deltaX;
  //const velocityX = detail.velocityX;
  console.log("Swiped:",detail)
}
*/
// --------------

// stores
import { Language } from "@/services/UserStore";

import { Modes } from "@/services/UserStore";

// user store
import { UserStore } from "@/services/UserStore";
import { useI18n } from "vue-i18n";
import { Version } from "@/services/GlobalDefs";
import { Signals } from "@/services/GlobalDefs";
// event bus
import eventBus from "@/services/eventBus";



import { useRoute } from "vue-router";
import AccountPopover from "@/components/popovers/AccountPopover.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TitleBar2',
  props: {
  title: String
},
  setup(__props) {

const userStore = UserStore();

// https://lokalise.com/blog/vue-i18n/
const { locale, availableLocales } = useI18n({ useScope: "global" });

// globals
const route = useRoute();

const current = ref("")
const cnt = ref(0)

watch(
  () => route.name,
  (name) => {
    const old = current.value
    current.value = name
    cnt.value++
    console.log(`TB: route is now : ${name}`);
    console.log("Old:",old,cnt.value)
    language.value = userStore.getLang();
    // trigger resize on workpane
    if (route.name == "Workspace") {
      console.log("Send resize");
      eventBus.emit(Signals.RESIZE);
    }
    // check token
    userStore.isTokenValid().then(()=>console.log("token checked"))
  }
);

const routeInfo = computed(() => {
  switch (route.name) {
    case "Info":
      return { title: "titles.about", thumb: "coffee" };
    case "Data":
      return { title: "titles.data", thumb: "book-atlas" };
    case "Stories":
      return { title: "titles.advanced", thumb: "book-open" };
    case "Instructions":
      return { title: "titles.tutorial", thumb: "receipt" };
    case "Advanced":
      return { title: "titles.advanced", thumb: "rocket" };
    case "Workspace":
      return { title: "titles.work.tab", thumb: "wand-magic-sparkles" };
    case "Login":
      return { title: "titles.login", thumb: "user-secret" };
    default:
      return { title: "undefined", thumb: "question" };
  }
});
const props = __props;

// thumbnail ref
const thumb = ref(["fas", "question"]);

// init store on mount
const hasToken = computed(() => {
  return userStore.exists();
});

const userText = computed(() => {
  return hasToken.value ? "tooltip.signedin" : "tooltip.signedout";
});

onBeforeMount(async () => {
  language.value = await userStore.getLang();

  // read mode
  dark.value = userStore.getDark();

});

const language = ref(locale.value);

const selectLanguage = () => {
  console.log("L:", language.value);
  userStore.setLang(language.value as Language);
  locale.value = userStore.getLang();
  console.log("Current lang:", locale.value);
};

// theme switch
const dark = ref(Modes.Dark);
const isDark = computed(() => userStore.getDark() == Modes.Dark);

const changeMode = () => {
  console.log("Change mode, now ", dark.value);
  dark.value = dark.value == Modes.Dark ? Modes.Light : Modes.Dark;
  userStore.setDark(dark.value);
  if (userStore.getDark() == Modes.Dark) {
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }
  // document.body.classList.toggle('dark', (theme.get() == Modes.Dark));
  console.log("Final mode is dark:", isDark.value);
};

const popover = ref();

async function userPop() {
  await openPop();
}
const openPop = async (message: string) => {
  popover.value = await popoverController.create({
    component: AccountPopover,
    //event: ev,
    size: "auto",
    side: "bottom",
    alignment: "start",
    showBackdrop: false,
    backdropDismiss: true,
    dismissOnSelect: true,
    reference: "trigger", // event or trigger
    componentProps: {
      // Popover props
      signal: "pop",
      message: userText.value,
    },
  });
  await popover.value.present();
  popover.value.open = true;
  const x = await popover.value.onDidDismiss();
  console.log("Dismiss: ", x);
  popover.value.open = false;
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_unref(IonHeader), {
    translucent: false,
    class: "titlebar"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(IonToolbar), { class: "toolbar" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonButtons), { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonMenuButton), {
                  "auto-hide": true,
                  color: "primary"
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButtons), { slot: "start" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(IonThumbnail), {
                    class: "logo",
                    button: "",
                    "router-link": "/"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", {
                        alt: "Daten.Cafe Logo",
                        src: "/img/logo/datencafe.svg"
                      }, null, -1)
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "headline ion-hide-sm-down" }, "Daten.Cafe", -1)),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(Version)), 1),
                    _createElementVNode("span", null, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "ion-hide-sm-down",
                        icon: routeInfo.value.thumb,
                        size: "1x"
                      }, null, 8, ["icon"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButtons), { slot: "end" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_unref(IonItem), { id: "modeRef" }, {
                    default: _withCtx(() => [
                      (isDark.value)
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 0,
                            button: "",
                            onClick: changeMode,
                            icon: ['fas', 'sun'],
                            size: "1x",
                            class: "modeLbl"
                          }))
                        : _createCommentVNode("", true),
                      (!isDark.value)
                        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                            key: 1,
                            button: "",
                            onClick: changeMode,
                            icon: ['fas', 'moon'],
                            size: "1x",
                            class: "modeLbl"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButtons), {
              slot: "end",
              id: "langRef"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'globe'],
                    size: "xl"
                  })
                ]),
                _createVNode(_unref(IonList), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonItem), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonSelect), {
                          placeholder: language.value,
                          interface: "popover",
                          onIonChange: selectLanguage,
                          modelValue: language.value,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((language).value = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availableLocales), (l, idx) => {
                              return (_openBlock(), _createBlock(_unref(IonSelectOption), {
                                key: idx,
                                value: l
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(l), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["placeholder", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButtons), {
              slot: "end",
              class: "lastItem",
              id: "userRef",
              onClick: userPop
            }, {
              default: _withCtx(() => [
                (hasToken.value)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: ['fas', 'user'],
                      size: "1x",
                      class: "tok"
                    }))
                  : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: ['fas', 'user-slash'],
                      size: "1x",
                      class: "notok"
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonToolbar), { class: "ion-hide-sm-up toolbar-secondary" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "headline-secondary" }, "Daten.Cafe ", -1)),
              _createElementVNode("span", null, [
                _createVNode(_component_font_awesome_icon, {
                  icon: routeInfo.value.thumb,
                  size: "sm"
                }, null, 8, ["icon"])
              ])
            ])
          ]),
          _: 1
        })
      ], 512)
    ]),
    _: 1
  }))
}
}

})