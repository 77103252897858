import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "prerelease" }
const _hoisted_4 = { class: "image" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["innerHTML"]

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonLabel, IonTitle, IonToolbar } from '@ionic/vue';
import { IonList, IonCard, IonImg, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue'
import { IonButton } from '@ionic/vue'

import { inject } from "vue"
import TitleBar from "@/components/TitleBar.vue"

// https://lokalise.com/blog/vue-i18n/
// if we need translation inside methods, import this as well:
import { useI18n } from 'vue-i18n'
import infoItems from "@/assets/info/info-md.json"

export default /*@__PURE__*/_defineComponent({
  __name: 'InfoPage',
  setup(__props) {


const prerelease = inject("prerelease")


const { t, locale } = useI18n({ useScope: 'global' })


const infoItem = (idx,id) => {
  //console.log(idx,id,locale.value)
  //console.log(storyItems[locale.value])
  const text = infoItems[locale.value][idx][id]
  return text
}

// ----------------------------------------------

/*
// text utils
import {StringUtils} from "../services/StringUtils"

StringUtils.bestMatch("abc",["abd","cbc","cbd"])
StringUtils.compare("abc","cbc")


*/

/*
// tensor flow
import * as tf from '@tensorflow/tfjs';

const f0 = async () => {

  // Define the model architecture
  const model = await tf.sequential();
  await model.add(tf.layers.conv2d({
      filters: 16,
      kernelSize: 3,
      activation: 'relu',
      inputShape: [16, 16, 1]
  }));
  await model.add(tf.layers.maxPooling2d({ poolSize: 2 }));
  await model.add(tf.layers.flatten());
  await model.add(tf.layers.dense({ units: 32, activation: 'relu' }));
  await model.add(tf.layers.dense({ units: 2, activation: 'softmax' }));

  // Compile the model
  await model.compile({
      optimizer: 'sgd',
      loss: 'categoricalCrossentropy',
      metrics: ['accuracy']
  });

  const modelJson = await model.toJSON();

  // Sample training data
  // 4 examples
  const xIn = tf.tensor([
      // First 16x16 image
      [
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          // Rest of the first image
      ],
      // Second 16x16 image
      [
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          // Rest of the second image
      ],
      // Third 16x16 image
      [
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          // Rest of the third image
      ],
      // Fourth 16x16 image
      [
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
          [[0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0], [0]],
      ]
  ])

  const yIn = tf.tensor([
      [1, 0], // Circle
      [0, 1], // Square
      [0, 1], // Square
      [1, 0], // Circle
      // Rest of the training data
  ]);

  // Total number of training examples
  const numExamples = yIn.shape[0]; // returns 200 for example data
  console.log ("Samples:",numExamples)

  const numTestExamples = 2;
  const xTest = xIn.slice([0, 0], [numTestExamples, xIn.shape[1]]);
  const xTrain = xIn.slice([numTestExamples, 0], [-1, -1]);
  const yTest = yIn.slice([0, 0], [numTestExamples, yIn.shape[1]]);
  const yTrain = yIn.slice([numTestExamples, 0], [-1, -1]);


  // Train the model
  const history = await model.fit(xTrain, yTrain, {
      epochs: 10,
      validationData: [xTest, yTest],
      verbose: 1,
      callbacks: {
        onEpochEnd: (epoch, logs) => {
          console.log(`Epoch ${epoch+1} - loss: ${logs.loss.toFixed(4)} - acc: ${logs.acc.toFixed(4)} - val_loss: ${logs.val_loss.toFixed(4)} - val_acc: ${logs.val_acc.toFixed(4)}`);
        }
      }       
  });
  // Evaluate the model on a test dataset
  const evalResult = await model.evaluate(xTest, yTest);
  console.log(JSON.stringify({ model: modelJson, result: evalResult }))

}
*/


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), {
        translucent: true,
        class: "hdr"
      }),
      _createVNode(_unref(IonContent), { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(prerelease))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("prerelease")), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(infoItems).en, (item, i) => {
              return (_openBlock(), _createBlock(_unref(IonCard), {
                color: "light",
                key: i
              }, {
                default: _withCtx(() => [
                  _createElementVNode("article", null, [
                    _createVNode(_unref(IonCardHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonCardTitle), null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(infoItem(i,"title")), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_unref(IonImg), {
                            src: item.image,
                            alt: item.alt
                          }, null, 8, ["src", "alt"]),
                          _createVNode(_unref(IonLabel), { class: "attribution" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.attribution), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createVNode(_unref(IonCardSubtitle), null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(infoItem(i,"date")), 1),
                            (infoItem(i,"link") > "")
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  href: infoItem(i,"link"),
                                  target: "_blank"
                                }, _toDisplayString(infoItem(i,"link")), 9, _hoisted_5))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(IonCardContent), { class: "infoCard" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          innerHTML: infoItem(i,'html'),
                          class: "tutor"
                        }, null, 8, _hoisted_6)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})