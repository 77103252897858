import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

import { onMounted, ref, provide } from "vue";
import { UserStore, Modes } from "@/services/UserStore";
import { PreFixes } from "@/services/GlobalDefs";

import { useI18n } from "vue-i18n";
import { IonHeader, IonTitle, IonToolbar } from '@ionic/vue';

import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";

import MainMenu from "@/components/MainMenu.vue";
import TitleBar2 from "@/components/TitleBar2.vue";
import PrintPage from "@/views/PrintPage.vue";

// prerelase state
import { createGesture } from "@ionic/vue";
import { useRoute } from "vue-router";
import router from "@/router";
const forceMobile = true;

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const userStore = UserStore();

const { locale } = useI18n({ useScope: "global" });
 
 provide('prerelease', true)



// do not track ...
onMounted(async () => {
  console.log("DNT:", navigator.doNotTrack);
  await userStore.clear();
  await userStore.setLang(locale.value);
  await checkLSToken();
  const mediaQueryObj = window.matchMedia("(prefers-color-scheme: dark)");
  const isDarkMode = mediaQueryObj.matches;
  if (isDarkMode) {
    console.log("Dark");
    await userStore.setDark(Modes.Dark);
  }
});

// check if we have a token in localstorage for this domain
const checkLSToken = async () => {
  try {
    const tok = await localStorage.getItem(PreFixes.LSTOKPREFIX);
    const key = await localStorage.getItem(PreFixes.LSKEYPREFIX);
    const loc = await localStorage.getItem(PreFixes.LSLOCPREFIX);
    if (tok === null || key === null || loc === null) {
      console.log("LS empty");
      return;
    }
    const current = window.location.hostname;
    if (current != loc) {
      console.log("LS wrong");
      await userStore.setToken(""); // clear
      return;
    }
    await userStore.setToken(tok, key);
    console.log("Token set");
  } catch (e) {
    console.log("LS error", e);
    await userStore.setToken(""); // clear
    return;
  }
};

// test swiping. works. move to app ...
const route = useRoute();


const gesture = ref();
const debounce = ref(false);
onMounted(() => {
  if (
    forceMobile ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    // Code to run if the app is running on a mobile device
    console.log("Running on a mobile device.");

    const swipe = document.getElementById("main-content");
    console.log("swipe target:", swipe);
    gesture.value = createGesture({
      el: swipe,
      onMove: (detail) => {
        onMove(detail);
      },
    });

    gesture.value.enable();
  }
  // get  app id from user store
  const appId = userStore.getAppId()
  console.log("ID:",appId)

});

const onMove = (detail) => {
  //const type = detail.type;
  //const currentX = detail.currentX;
  //const deltaX = detail.deltaX;
  //const velocityX = detail.velocityX;
  const current = route.name;
  // debounce. first
  if (debounce.value) return;
  debounce.value = true;
  setTimeout(() => {
    debounce.value = false;
  }, 250);
  //
  console.log("Swiped:", current, detail);

  let left = "";
  let right = "";
  switch (route.name) {
    case "Info":
      left = "";
      right = "Data";
      break;
    case "Data":
      left = "Info";
      right = "Stories";
      break;
    case "Stories":
      left = "Data";
      right = "Instructions";
      break;
    case "Instructions":
      left = "Data";
      right = "Workspace";
      break;
    case "Workspace":
      // check position here
      if (detail.currentY < 100) {
        left = "Instructions";
        right = "Advanced";
      } else {
        console.log("ignore");
      }
      break;
    case "Advanced":
      left = "Workspace";
      right = "Login";
      break;
    case "Login":
      left = "Advanced";
      right = "";
      break;
    default:
      break;
  }

  console.log(left, right);
  if (detail.type == "pan") {
    if (detail.velocityX > 1) {
      if (left > "") {
        router.push({ name: left });
      }
    }
    if (detail.velocityX < -1) {
      if (right > "") {
        router.push({ name: right });
      }
    }
  }
};
// --------------

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_unref(route).name != "PrintPage"?"screen":"print")
      }, [
        (_unref(route).name != "PrintPage")
          ? (_openBlock(), _createBlock(MainMenu, { key: 0 }))
          : _createCommentVNode("", true),
        (_unref(route).name != "PrintPage")
          ? (_openBlock(), _createBlock(TitleBar2, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(_unref(IonRouterOutlet), {
          class: _normalizeClass(_unref(route).name != "PrintPage"?"screen":"print"),
          id: "main-content",
          animated: "false"
        }, null, 8, ["class"])
      ], 2)
    ]),
    _: 1
  }))
}
}

})