import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonContent } from '@ionic/vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AccountPopover',
  props: {
  signal:String,
  message: String
},
  setup(__props) {

const props = __props



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), { class: "ion-padding ion-popover" }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(props.message)), 1)
    ]),
    _: 1
  }))
}
}

})